<template>
  <div>
    <b-card>
      <b-tabs pills>
        <b-tab>
          <template #title>
            <feather-icon icon="HomeIcon" />
            <span>Evaluations</span>
          </template>

          <b-tabs
            vertical
            nav-wrapper-class="nav-vertical"
          >
            <b-tab
              active
              title="Applied evaluations changes"
            >
              <b-aspect :aspect="aspect">
                <vue-perfect-scrollbar>
                  <div
                    v-for="(log, index) in LogGetAppliedEvaluationsChanges"
                    :key="index"
                  >
                    <p style="margin-bottom: 0px;">
                      Evaluation: "{{ log.EvaluationName }}" with user {{ log.UserName }}
                    </p>
                    <p style="margin-bottom: 0px; font-size: 10px;">
                      Change: "{{ log.ChangeType }}" on {{ log.CreatedOn | datetime-format }}
                    </p>
                    <p style="font-size: 10px;">
                      Previous value: "{{ log.PreviousValue }}" || Actual value: "{{ log.ActualValue }}"
                    </p>
                    <hr>
                  </div>
                </vue-perfect-scrollbar>
              </b-aspect>
            </b-tab>
          </b-tabs>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTabs, BTab, BAspect,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import logService from '@core/services/logs/logService'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BAspect,
    VuePerfectScrollbar,
  },
  data() {
    return {
      aspect: '16:9',
      LogGetAppliedEvaluationsChanges: [],
    }
  },
  mounted() {
    this.getAppliedEvaluationChanges()
  },
  methods: {
    getAppliedEvaluationChanges() {
      logService.getAppliedEvaluationsChanges({}).then(response => {
        this.LogGetAppliedEvaluationsChanges = response.data.logs
      })
    },
  },
}
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.b-aspect {
  padding: 1.5rem;
  border: 1px solid #eee;
  background-color: black ;
  border-radius: 10px;
  height: 700px;
}
.b-aspect p {
  color: white;
}
.ps-container {
  height: 660px;
}
</style>
