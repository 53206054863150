import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class LogService {
    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    getAppliedEvaluationsChanges(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getAppliedEvaluationsChanges, ...args)
    }
}

function useLogService() {
  const logService = new LogService()

  return {
    logService,
  }
}

const { logService } = useLogService()
export default logService
